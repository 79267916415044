import React from "react";
import { Draggable } from "react-beautiful-dnd";

// styles
import DragHandleIcon from "@material-ui/icons/DragHandle";

type Props = {
  id: string;
  index: number;
  isDisabled?: boolean;
  children: React.ReactNode;
};

const Drag = ({ id, index, isDisabled, children }: Props) => (
  <Draggable draggableId={id} index={index} isDragDisabled={isDisabled}>
    {(provided) => {
      return (
        <div
          className="flex gap-2 items-center py-2 "
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <div {...provided.dragHandleProps}>
            <DragHandleIcon />
          </div>
          {children}
        </div>
      );
    }}
  </Draggable>
);

export default Drag;
