import { Link } from "@remix-run/react";
import {
  ProductItem,
  RecipeIngredient,
  RecipeProduct,
  Retailer,
} from "~/features/Recipes/types";
import { Lang } from "~/types";

type Props = {
  recipeIngredient: RecipeIngredient;
  retailers: Retailer[];
  lang: Lang;
  showMoreId?: string;
  index: number;
  showAllProducts: boolean;
};

const DropDownProductsList = ({
  recipeIngredient,
  retailers,
  lang,
  showMoreId,
  index,
  showAllProducts,
}: Props) => {
  const { ingredient } = recipeIngredient;
  const productList = ingredient.products
    .filter((prod: RecipeProduct) => prod.retailer)
    .map((product: RecipeProduct) => {
      const retailer = retailers.find(
        (r: Retailer) => product.retailer === r.id
      );
      return {
        ...product,
        retailerObj: retailer,
      };
    });

  const showMore = showMoreId === index.toString() || showAllProducts;

  return (
    <div className={`more-info ${showMore ? "extended" : ""}`}>
      <ul className={showMore ? "flex flex-col pt-3 pb-2 pl-8" : "pl-8"}>
        {productList?.map((productItem: ProductItem, index: number) => {
          return (
            <Link
              className="hover:text-primary-100"
              target="_blank"
              to={"/products/" + productItem.id}
            >
              <li
                key={index}
                style={
                  productItem.ingredientBrand ? { background: "#E1E1D1" } : {}
                }
              >
                <span className="pl-2 pr-2">
                  {productItem.retailerObj ? (
                    <span>{productItem.retailerObj.name}</span>
                  ) : null}
                </span>
                <span className="pr-2">{productItem.price}</span>
                <span className="pr-2">{productItem.quantity}</span>
                <span className="pr-2">
                  {productItem.measure?.title?.[lang]}
                </span>
                <span>{productItem.title}</span>
              </li>
            </Link>
          );
        })}
      </ul>
    </div>
  );
};

export default DropDownProductsList;
